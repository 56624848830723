<!-- 协议 -->
<template>
  <div class="xieyi">
    <div class="bese"></div>
    <div class="dingbu"></div>
    <div class="banxin">
      <div class="card" v-html="tcp"></div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/login.js";
export default {
  data() {
    return {
      tcp: "",
    };
  },
  watch: {},
  mounted() {
    let a = window.sessionStorage.getItem('xieyi');
    this.xieyi(a);
  },
  methods: {
    async xieyi(num) {
      this.tcp = "";
      if (num == 1) {
        document.title = '用户协议'
        // 用户协议
        let res = await API.agreement().then((res) => {
          if (res.code == 1) {
            this.tcp = res.data;
          }
        });
      } else {
        document.title = '隐私政策'
        // 隐私政策
        let res = await API.privacy().then((res) => {
          if (res.code == 1) {
            this.tcp = res.data;
          }
        });
      }
    },
  },
  beforeDestroy() {
    window.localStorage.removeItem("xieyi");
    // console.log("已经销毁");
  },
};
</script>

<style scoped lang="scss">
.bese {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #eff5ff !important;
  z-index: -1;
}
.xieyi {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .dingbu {
    width: 100%;
    height: 127px;
    background: url("../../assets/img/login/ding.png");
    background-size: 100% 100%;
  }
  .banxin {
    width: 1200px;
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }
  .card {
    width: 1200px;
    background: #ffffff;
    box-shadow: 3px 8px 11px 0 rgba(49, 60, 87, 0.12);
    border-radius: 20px;
    padding: 29.03px 101px 17px 69px;
    box-sizing: border-box;
    margin-bottom: 91px;
    ::v-deep(ol) {
      display: inline-block !important;
      width: 100% !important;

      li {
        width: 100% !important;
        display: inline-block !important;
      }

      p {
        width: 100% !important;
      }
    }
    ::v-deep(ul) {
      display: inline-block !important;
      width: 100% !important;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>
