// 登录页接口
import request from '../request'

export default class logn {
    /**
    * 密码登录
    * @param {*} params
    * @returns
    */
    static login(data) {
        return request.post('/api/auth/login', data)
    }

    /**
* 手机发送验证码
* @param {*} params
* @returns
*/
    static sms(data) {
        return request.post('/api/sms/send', data)
    }
    /**
* 手机号注册
* @param {*} params
* @returns
*/
    static register(data) {
        return request.post('/api/auth/register', data)
    }
    /**
      * 用户协议
      * @param {*} params
      * @returns
      */
    static agreement() {
        return request.get('/api/info/agreement')
    }
    /**
    * 隐私政策
    * @param {*} params
    * @returns
    */
    static privacy() {
        return request.get('/api/info/privacy')
    }
    /**
* 修改密码
* @param {*} params
* @returns
*/
    static changepwd(data) {
        return request.post('/api/user/changepwd', data)
    }
    /**
* 手机验证码登录
* @param {*} params
* @returns
*/
    static mobilelogin(data) {
        return request.post('/api/auth/mobilelogin', data)
    }
    /**
* 重置密码
* @param {*} params
* @returns
*/
    static resetpwd(data) {
        return request.post('/api/auth/forgetpwd', data)
    }
    /**
* 微信扫码登录
* @param {*} params 
* @returns 
*/
    static getAuthorizeUrl(params) {
        return request.get("/api/third/getAuthorizeUrl", {
            params
        })
    }
    /**
* 重置密码
* @param {*} params
* @returns
*/
    static callback(data) {
        return request.post('/api/third/callback', data)
    }
    /**
* 第三方授权回调
* @param {*} params
* @returns
*/
    static callback(data) {
        return request.post('/api/third/callback', data)
    }
    /**
* 绑定第三方
* @param {*} params
* @returns
*/
    static bind(data) {
        return request.post('/api/third/bind', data)
    }
    /**
* 修改昵称
* @param {*} params
* @returns
*/
    static changenickname(data) {
        return request.post('/api/user/changenickname', data)
    }
    /**
 * 会员登录绑定信息
 * @param {*} params
 * @returns
 */
    static sendChat(data) {
        return request.post('/api/chat/send', data)
    }
    /**
*  验证密保问题
* @param {*} params
* @returns
*/
    static userquertion(data) {
        return request.post('/api/auth/userquertion', data)
    }
    /**
* 个人信息
* @param {*} params
* @returns
*/
    static userHomepage() {
        return request.get('/api/v1.usercontrol/userHomepage')
    }
}